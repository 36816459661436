import React from 'react';
import { ThemeProvider } from 'styled-components';
import Sticky from 'react-stickynode';

import { driverTheme } from 'common/theme/index';

import { ResetCSS } from 'common/assets/css/style';

import { DrawerProvider } from 'common/contexts/DrawerContext';

import { transitions, positions, Provider as AlertProvider } from 'react-alert';
import AlertTemplate from 'react-alert-template-basic';

import Seo from 'components/seo';

import Navbar from 'containers/Navbar';
import SideSelector from 'containers/SideSelector';
import Features from 'containers/Features';
import ContactForm from 'containers/Contact';
import Footer from 'containers/Footer';

import GlobalStyle, {
  AppWrapper,
  ContentWrapper,
} from 'containers/index.style.js';

// optional configuration
const options = {
  // you can also just use 'bottom center'
  position: positions.TOP_RIGHT,
  timeout: 3000,
  offset: '30px',
  // you can also just use 'scale'
  transition: transitions.SCALE,
};

const App = () => {
  return (
    <ThemeProvider theme={driverTheme}>
      <AlertProvider template={AlertTemplate} {...options}>
        <Seo title="Driver Landing Title" />
        <ResetCSS />
        <GlobalStyle />

        <AppWrapper>
          <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
            <DrawerProvider>
              <Navbar main />
            </DrawerProvider>
          </Sticky>

          <ContentWrapper>
            <SideSelector />
            <Features />
            <ContactForm fullwhite main />
            <Footer />
          </ContentWrapper>
        </AppWrapper>
      </AlertProvider>
    </ThemeProvider>
  );
};

export default App;
